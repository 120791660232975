<template>
	<div class="demo-spin-container">
		<div class="my-alert alert-warning">
			<span class="my-alert-icon">
				<Icon size="16" color="#ff9900" type="md-alert" />
			</span>
			<div class="my-alert-info">
				<p style="font-weight: bold;">安全提示</p>
				<ul class="item-ul" style="padding-top: 4px;">
					<li class="">
						<p>您的 API 密钥代表您的账号身份和所拥有的权限，使用 API 可以操作您名下的所有资源。</p>
					</li>
					<li class="">
						<p>为了您的财产和服务安全，请妥善保存和定期更换密钥，请勿通过任何方式（如 GitHub）上传或者分享您的密钥信息。</p>
					</li>
				</ul>
			</div>
		</div>
		<div class="my-card">
			<div class="card-item">
				<div class="card-item-box">
					<div class="card-head">
						<h3 class="title">API 密钥</h3>
					</div>
					<div class="card-content">
						<div class="card-form-item">
							<div class="form-item-label">SecretKey</div>
							<div class="dorm-item-value">
								<a @click="openModal">查看API密钥</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Modal v-model="modal" title="查看SecretKey">
			<div class="ser-form">
				<Form :model="formData" :label-width="100">
					<FormItem label="登录账号">
						<Input v-model="formData.adm_name" maxlength="11" placeholder="请输入登录账号"></Input>
					</FormItem>
					<FormItem label="登录密码">
						<Input v-model="formData.adm_pwd" type="password" password maxlength="12"
							placeholder="请输入6-12位登录密码"></Input>
					</FormItem>
				</Form>
			</div>
			<div slot="footer">
				<Button @click="modal = false">取消</Button>
				<Button @click="confirm" type="primary">确定</Button>
			</div>
		</Modal>
		<Modal v-model="ViewModal" title="查看SecretKey" :mask-closable="false" footer-hide>
			<div>
				<div class="my-alert">
					<span class="my-alert-icon">
						<Icon size="16" color="#002da0" type="md-alert" />
					</span>
					<div class="my-alert-info">
						<p style="font-weight: bold;">安全提示</p>
						<ul class="item-ul" style="padding-top: 4px;">
							<li class="">
								<p>请注意该页面关闭再次查看需要重新输入账号、密码查看。</p>
							</li>
							<li class="">
								<p>请妥善保存密钥，请勿通过任何方式上传或者分享您的密钥信息。</p>
							</li>
						</ul>
					</div>
				</div>
				<div class="">
					<span>SecretId：</span>
					<span>{{ secret_id }}</span>
				</div>
				<div class="">
					<span>secretKey：</span>
					<span>{{ secret_key }}</span>
				</div>
			</div>

		</Modal>
		<!-- 加载中	 -->
		<Spin fix v-if="loading_show"></Spin>
	</div>
</template>

<script>
	import md5 from 'js-md5'
	export default {
		name: "Interface",
		data() {
			return {
				modal: false,
				ViewModal: false,
				formData: {
					adm_name: '',
					adm_pwd: '',
				},
				secret_id: '',
				secret_key: '',
				loading_show: false,	//是否加载
			}
		},
		created() {
			this.init()
		},
		methods: {
			openModal() {
				this.formData.adm_name = ''
				this.formData.adm_pwd = ''
				this.modal = true
			},
			confirm() {
				var that = this
				if (!this.formData.adm_name) return this.alertErr('登录名不能为空！');
				var pattern = new RegExp("^1[3456789][0-9]{9}$", 'i');
				if (!pattern.test(this.formData.adm_name)) return this.alertErr('登录名格式有误！');
				if (!this.formData.adm_pwd) return this.alertErr('登录密码不能为空！');
				var pwd = JSON.parse(JSON.stringify(this.formData.adm_pwd));
				var regx = /^[a-z0-9]{6,12}$/i
				if (!regx.test(pwd)) return this.alertErr('登录密码格式有误！');
				pwd = md5(pwd).substr(2, 28);
				this.requestApi('/adm/apply_apikey', {
					adm_name: this.formData.adm_name,
					adm_pwd: pwd
				}).then(function(res) {
					if (res.status) {
						that.modal = false
						that.secret_id = res.secret_id
						that.secret_key = res.secret_key
						that.ViewModal = true
					} else {
						that.alertErr(res.msg)
					}
				})
			},
		}
	}
</script>

<style scoped>
	.my-alert {
		font-size: 12px;
		line-height: inherit;
		padding: 14px 20px;
		vertical-align: middle;
		color: #002da0;
		border: 1px solid #d5e7ff;
		border-radius: 0;
		background: #d5e7ff;
		position: relative;
		box-sizing: border-box;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.alert-warning {
		color: #a02800;
		background-color: #ffe8d5;
		border-color: #ffe8d5;
	}

	.my-alert-icon {
		font-size: 0;
		flex: 0 0 16px;
		margin-right: 16px;
		margin-top: 1px;
	}

	.my-alert-info {
		flex: 1;
		font-size: 12px;
		line-height: inherit;
		color: inherit;
	}

	.item-ul {
		color: inherit;
		padding: 10px 0 0;
		list-style: none;
	}

	.item-ul>li {
		position: relative;
		padding-left: 10px;
		line-height: 1.5;
		margin-bottom: 8px;
		box-sizing: border-box;
	}

	.item-ul li:last-of-type {
		margin-bottom: 0;
	}

	.card-item {
		display: inline-block;
		width: 100%;
		vertical-align: top;
		font-size: 12px;
		box-sizing: border-box;
		margin-bottom: 20px;
	}

	.card-item-box {
		position: relative;
		background-color: #fff;
		box-shadow: 0 2px 3px 0 rgb(0 0 0 / 20%);
		padding: 20px;
		margin-left: auto;
		margin-right: auto;
		border: 0;
		margin-bottom: 0;
		box-sizing: border-box;
	}

	.card-head {
		margin-bottom: 8px;
	}

	.card-head .title {
		font-size: 14px;
		font-weight: 700;
	}

	.card-form-item {
		display: flex;
		font-size: 14px;
		line-height: 32px;
	}

	.form-item-label {
		width: 100px;
		padding-right: 10px;
	}

	.flex {
		display: flex;
		align-items: center;
	}
	
	.demo-spin-container {
		position: relative;
	}
</style>